<template>
	<div class="edit-form-container" :class="{ 'half-width': !fromAnotherInstance }">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form"
			label-width="150px"
			:model="formData"
			:rules="rules"
		>
			<el-form-item label="Имя" prop="first_name">
				<el-input v-model="formData.first_name" />
			</el-form-item>

			<el-form-item label="Фамилия" prop="last_name">
				<el-input v-model="formData.last_name" />
			</el-form-item>

			<el-form-item label="Роль" prop="type">
				<el-select v-model="formData.type" placeholder="Выберите роль">
					<el-option
						v-for="item in userTypesList"
						:key="'type-' + item.id"
						:label="item.name"
						:value="item.id"
					/>
				</el-select>
			</el-form-item>

			<el-form-item label="Email" prop="email">
				<el-input v-model="formData.email" type="email" />
			</el-form-item>

			<el-form-item label="Телефон" prop="phone_number">
				<el-input v-model="formData.phone_number" type="phone_number" />
			</el-form-item>

			<el-form-item
				v-if="!itemId || authUser.id === itemId"
				label="Пароль"
				prop="password"
			>
				<el-input v-model="formData.password" type="password" />
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { userTypesList } from '@/constants/global';
import { required } from '@/constants/validation';
import { itemFormMixin } from '@/mixins';

export default {
	mixins: [itemFormMixin],

	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,

			formData: {
				type: 2,
				first_name: '',
				last_name: '',
				email: '',
				password: '',
				phone_number: ''
			},

			rules: {
				first_name: required,
				last_name: required,
				email: required
			}
		};
	},

	computed: {
		...mapState({
			authUser: state => state.auth.authUser
		}),

		userTypesList: () => userTypesList
	},

	methods: {
		/*setupPage(item) {
			// console.log(item);
			const prop = 'formData';
			const idProp = 'id';

			if (item) {
				this.itemId = item[idProp];
				this[prop] = {
					...this.setupForm(item, this[prop]),
					type: item.type
				};
			}
		}*/
	}
};
</script>
